import React from "react"

import LayoutSecondary from "../../components/layout-secondary";
import Seo from "../../components/seo"
import {injectIntl} from "gatsby-plugin-react-intl"
import HeroResources from "../../components/heroes/hero-resources";
import {Container, Row, Col} from "react-bootstrap";
import ResourcesData from "../../content/resources/index.json";
import CasesData from "../../content/case-studies/index.json";
import PapersData from "../../content/white-papers/index.json";
import SimpleCard from "../../components/misc/simple-card";

const ResourcesIndexPage = ({ intl, location }) => (
    <LayoutSecondary>
        <Seo lang={intl.locale}
             title={ResourcesData[intl.locale].title}
             description={ResourcesData[intl.locale].text}
             pathname={location.pathname}
        />
        <HeroResources
            theme={`secondary`}
            content={ResourcesData[intl.locale]}
        />
        <div className={`top-main-overflow-wrapper`}>
            <Container>
              <Row>
                  {CasesData[intl.locale].cases.map((item, i) => {
                      if (!item.cta) {
                          return (
                              <Col key={i} md={4}>
                                  <SimpleCard content={item}/>
                              </Col>
                          )
                      } else {
                          return null;
                      }
                  })}
                  {PapersData[intl.locale].papers.map((item, i) => {
                      if (!item.cta) {
                          return (
                              <Col key={i} md={4}>
                                  <SimpleCard content={item}/>
                              </Col>
                          )
                      } else {
                          return null;
                      }
                  })}
              </Row>
            </Container>
        </div>
    </LayoutSecondary>
)

export default injectIntl(ResourcesIndexPage)
