import React from "react"
import { Container } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"

const HeroResources = ({ intl, theme, content }) => (
    <div className={`hero-resources ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <h1>{content.title}</h1>
            </Container>
        </div>
    </div>
)

export default injectIntl(HeroResources)
