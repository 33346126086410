import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {graphql, useStaticQuery} from "gatsby";
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false;
library.add(fab, fas);

const LayoutSecondary = ({ children }) => {
    const [ headerClass, setHeaderClass ] = React.useState("");

    React.useEffect(_ => {
        window.addEventListener('scroll', handleScroll);
        return _ => {
            window.removeEventListener('scroll', handleScroll);
        }
    });

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setHeaderClass("on-scroll");
        } else {
            setHeaderClass("");
        }
    }

    const data = useStaticQuery(graphql`
    query SecondaryLayoutTitleQuery {
        site {
            siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} scrollClass={headerClass} theme={`secondary`} />
            <main className={`secondary-page ` + headerClass}>{children}</main>
            <Footer siteTitle={data.site.siteMetadata.title} theme={`dark`} />
        </>
    )
}

LayoutSecondary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSecondary
